<template>
  <div id="gallery-list">
    <!-- Main Galleries -->
    <div class="vx-row" v-show="isLoadedMainGallery">
      <!-- Main Galleries title -->
      <div class="vx-col w-full mb-5">
        <div class="flex justify-between items-center w-full">
          <h3 class="text-xl text-primary font-semibold">
            Main Galleries ({{ mainGalleries.length }} Photos)
          </h3>

          <div class="flex justify-end">
            <el-button size="medium"
                       type="primary"
                       class="block mt-4 float-left"
                       :loading="isSaving"
                       :disabled="!checkImageReady(mainGalleries)"
                       @click="submitUpload({ galleries: mainGalleries})">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              Upload to server
            </el-button>
          </div>
        </div>
      </div>

      <el-upload
        multiple
        drag
        class="w-full"
        type="primary"
        size="medium"
        ref="upload"
        accept="image/*"
        list-type="picture-card"
        :name="'attachment'"
        :headers="authHeaders()"
        :action="uploadHotelUrl"
        :auto-upload="false"
        :file-list="mainGalleries"
        :on-change="OnPhotoChange"
        :on-success="(response) => onUploadSuccess(true, null, response)"
        :on-error="onUploadError">
        <vs-row slot="trigger">
          <vs-col vs-w="12">
            <div>
              <i class="el-icon-upload"></i>
              <p class="el-upload__text">Drop file here or <em>click to add photo</em></p>
              <p class="el-upload__text">jpg/png files with a size 600x400px</p>
              <p class="el-upload__text">Max 3Mb per file</p>
            </div>
          </vs-col>
        </vs-row>

        <template v-slot:file="{ file }">
          <div class="mb-6">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url" alt=""
            >
            <vs-icon v-if="file.status === 'ready'" class="upload-status" icon-pack="feather" icon="icon-upload-cloud" />
            <vs-icon v-if="file.status === 'success'" class="upload-status" icon-pack="feather" icon="icon-check" />
            <vs-checkbox v-model="file.isMain"
                         :disabled="isSaving || file.isMain"
                         @change="setPhotoMain(file, mainGalleries)">
              Main Image
            </vs-checkbox>
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="removePhoto(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>

      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>

      <!-- List of Main Galleries -->
<!--      <div class="vx-row mt-4 px-5 w-full">-->
<!--        <div-->
<!--          :class="[-->
<!--            'vx-col w-full md:w-4/12 lg:w-3/12',-->
<!--            i !== mainGalleries.length - 1 ? 'mb-base' : ''-->
<!--          ]"-->
<!--          v-for="(gallery, i) in mainGalleries"-->
<!--          :key="i"-->
<!--        >-->
<!--          <div class="vx-card w-full">-->
<!--            <img :src="gallery.url" alt="" class="block w-full h-48" style="object-fit: cover !important" />-->

<!--            <div class="py-4 px-6">-->
<!--              <div class="flex justify-between items-center select-none">-->
<!--                <span class="text-primary cursor-pointer" @click="editPhoto(gallery.id, false)">Edit</span>-->
<!--                <span-->
<!--                  class="text-danger cursor-pointer"-->
<!--                  @click="confirmDelete(gallery.id, null)"-->
<!--                  >Delete</span-->
<!--                >-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <vs-divider class="py-5"></vs-divider>

    <!-- Product Property Galleries -->
    <div class="vx-row" v-show="isLoadedPropertyGallery">
      <div
        class="vx-col w-full"
        v-for="(room, i) in rooms"
        :key="i"
      >
        <div class="vx-row mb-5 pl-4">
          <div class="flex justify-between items-center w-full">
            <h3 class="text-xl text-primary font-semibold">
              {{ room.name }} ({{ room.images?.length ?? 0 }} Photos)
            </h3>

<!--            <vs-button icon-pack="feather" icon="icon-plus" class="mr-5" @click="addPhoto(room)"-->
<!--              >Add Photo</vs-button-->
<!--            >-->

            <div class="flex justify-end">
              <el-button size="medium"
                         type="primary"
                         class="block mt-4 float-left"
                         :loading="isSaving"
                         :disabled="!checkImageReady(room.images)"
                         @click="submitUpload({idx: i, galleries: room.images})">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                Upload to server
              </el-button>
            </div>
          </div>
        </div>

        <el-upload
          multiple
          drag
          class="w-full"
          type="primary"
          size="medium"
          ref="roomUpload"
          accept="image/*"
          list-type="picture-card"
          :name="'attachment'"
          :headers="authHeaders()"
          :action="uploadRoomUrl(room.id)"
          :auto-upload="false"
          :file-list="room.images"
          :on-change="(file, fileList) => OnPhotoChange(file, fileList, false, room)"
          :on-success="(response) => onUploadSuccess(false, room.id, response)"
          :on-error="onUploadError">
          <vs-row slot="trigger">
            <vs-col vs-w="12">
              <div>
                <i class="el-icon-upload"></i>
                <p class="el-upload__text">Drop file here or <em>click to add photo</em></p>
                <p class="el-upload__text">jpg/png files with a size 600x400px</p>
                <p class="el-upload__text">Max 3Mb per file</p>
              </div>
            </vs-col>
          </vs-row>

          <template v-slot:file="{ file }">
            <div class="mb-6">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url" alt=""
              >
              <vs-icon v-if="file.status === 'ready'" class="upload-status" icon-pack="feather" icon="icon-upload-cloud" />
              <vs-icon v-if="file.status === 'success'" class="upload-status" icon-pack="feather" icon="icon-check" />
              <vs-checkbox v-model="file.isMain"
                           :disabled="isSaving || file.isMain"
                           @change="setPhotoMain(file, room.images, false, room.id)">
                Main Image
              </vs-checkbox>
              <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="removePhoto(file, room.id)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
            </div>
          </template>
        </el-upload>

<!--        <div class="vx-row w-full">-->
<!--          <div-->
<!--            class="vx-col w-full md:w-4/12 lg:w-3/12"-->
<!--            v-for="(image, i) in room.images"-->
<!--            :key="i"-->
<!--          >-->
<!--            <div class="vx-card">-->
<!--              <img :src="image.url" alt="" class="block w-full h-48" style="object-fit: cover !important"  />-->

<!--              <div class="py-4 px-6">-->
<!--                <div class="flex justify-between items-center select-none">-->
<!--                  <span class="text-primary cursor-pointer"-->
<!--                        @click="editPhoto(image.id, room)">Edit</span>-->
<!--                  <span-->
<!--                    class="text-danger cursor-pointer"-->
<!--                    @click="confirmDelete(image.id, room.id)"-->
<!--                    >Delete</span-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <vs-divider
          class="py-3"
          v-if="i !== rooms.length - 1"
        ></vs-divider>
      </div>
    </div>

    <!-- Form Popup -->
    <div class="centerx">
      <vs-popup class="holamundo" title="Add Photo" :active.sync="popup">
        <div class="vx-row">
          <!-- Set as main photo on brand -->
          <div class="vx-col w-full mb-3" v-if="productPropertyID == null">
            <vs-checkbox
              v-model="payload.isMain"
            >
              Set as main photo
            </vs-checkbox>
          </div>

          <div class="vx-col w-full mb-3" v-if="productPropertyID != null">
            <vs-checkbox
              v-model="payload.isMain"
            >
              Set as main photo on <strong>{{ productPropertyID.name }}</strong>
            </vs-checkbox>
          </div>

          <!-- Select status -->
          <div class="vx-col w-full">
            <span class="mb-2 block text-sm">Show on Main Galleries</span>
            <v-select
              label="label"
              :options="statusOptions"
              :reduce="status => status.value"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.status"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("status")
            }}</span>
          </div>

          <!-- Select Product Properties -->
          <div class="vx-col w-full">
            <span class="mb-2 block text-sm">Product Properties</span>
            <v-select
              multiple
              label="name"
              :options="rooms"
              :reduce="property => property.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.product_properties"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("product_properties")
            }}</span>
          </div>

          <!-- Upload an image -->
          <div class="vx-col w-full mt-3">
            <div class="vx-row">
              <span class="text-base mb-3 ml-4">Image (*)</span>
              <div class="vx-col w-11/12">
                <img :src="url" alt="" class="block w-full" height="400" />
                <div>
                  <input
                    type="file"
                    @change="addImage"
                    class="hidden"
                    ref="update_logo_input"
                    accept="image/*"
                  />
                </div>
              </div>

              <div class="vx-col w-1/12">
                <div class="vx-row mb-3">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-file"
                    @click="$refs.update_logo_input.click()"
                  ></vs-button>
                </div>
              </div>
            </div>
          </div>

          <div class="vx-col w-full mt-base">
            <vs-button icon-pack="feather" icon="icon-save" @click="storeData"
              >Save Image</vs-button
            >
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import axios, { authHeaders, baseURL } from '@/axios'
import vSelect from "vue-select";

export default {
  name: "PropertyGalleries",
  metaInfo: {
    title: "Galleries",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect
  },

  data() {
    return {
      mainGalleries: [],
      isLoadedMainGallery: false,

      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,

      rooms: [],
      isLoadedPropertyGallery: false,

      popup: false,
      payload: {
        image: null,
        status: 1,
        isMain: false,
        product_properties: []
      },
      url: "",
      galleryID: null,
      productPropertyID: null,

      // Options
      statusOptions: [
        {
          label: "Yes",
          value: 1
        },
        {
          label: "No",
          value: 0
        }
      ],
      isSaving: false,
    };
  },
  computed: {
    hotelId() {
      return this.$store.state.userData().userInfo.hotelId;
    },
    uploadHotelUrl() {
      return `${baseURL}/hotels/${this.hotelId}/images`;
    }
  },
  methods: {
    authHeaders,
    uploadRoomUrl(roomId) {
      return `${baseURL}/hotels/${this.hotelId}/rooms/${roomId}/images`;
    },
    async removePhoto(file, roomId) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Confirm Delete",
        text: `You are about to delete this image`,
        accept: () => this.doRemovePhoto(file, roomId),
        acceptText: "Delete"
      });
    },
    async doRemovePhoto(file, roomId) {
      if (file.isMain) {
        this.$vs.notify({
          title: "Error",
          text: "Can't delete main image, change other image as main image first.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          time: 5000
        });
        return;
      }
      if (file.id) {
        const url = !roomId ? `/hotels/${this.hotelId}/images/${file.id}` :
          `/hotels/${this.hotelId}/rooms/${roomId}/images/${file.id}`;
        await axios.delete(url);
      }
      if (!roomId) {
        this.mainGalleries = this.mainGalleries.filter(f => f.url !== file.url);
      } else {
        const room = this.rooms.find(x => x.id === roomId);
        room.images = room.images.filter(f => f.url !== file.url);
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    submitUpload({ idx, galleries }) {
      const isMainExists = !idx ? this.checkIsMainExists(this.mainGalleries) :
        this.checkIsMainExists(galleries);
      if (!isMainExists) {
        this.$vs.notify({
          title: "Error",
          text: 'Main Image is not set, upload rejected.',
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          time: 5000
        });
        return;
      }
      this.isSaving = true;
      if (idx !== undefined) {
        this.$refs.roomUpload[idx].submit();
      } else {
        this.$refs.upload.submit();
      }
    },
    checkImageReady(galleries) {
      let check = false;
      for (const gall of galleries) {
        if (gall.status === 'ready') return true;
        check = false
      }
      return check;
    },
    checkIsMainExists(galleries) {
      let check = false;
      for (const gall of galleries) {
        if (gall.isMain) return true;
        check = gall.isMain
      }
      return check;
    },
    onUploadSuccess(isHotelPhoto = true, roomId, response) {
      this.isSaving = false;
      this.$vs.notify({
        title: "Success",
        text: 'Upload Success',
        iconPack: "feather",
        icon: "icon-check-circle",
        color: "success",
      });
      const updateHotelPhoto = this.updateHotelPhoto;
      let mainGalleries = this.mainGalleries;
      let room = this.rooms.find(x => x.id === roomId);

      const updates = async () => {
        const galleries = !roomId ? mainGalleries : room.images;
        for (const gallery of galleries) {
          await updateHotelPhoto({
            id: gallery.id, isMain: gallery.isMain, isHotelPhoto, roomId,
          });
        }
      }

      const tempMain = JSON.parse(JSON.stringify(this.mainGalleries))
        .filter(x => x.status === 'ready');
      console.log(this.rooms)
      const tempRooms = JSON.parse(JSON.stringify(this.rooms))
        .filter(x => x.id !== roomId);
      const roomGalleries = [];
      for (const room of tempRooms) {
        const galleriesReady = room.images.filter(x => x.status === 'success');
        if (galleriesReady.length > 0) {
          roomGalleries.push([ ...galleriesReady ]);
          console.log(roomGalleries)
        }
      }

      if (isHotelPhoto) {
        this.getMainGalleries().then(async () => {
          mainGalleries = this.mainGalleries;
          this.isSaving = true;
          await updates()
          this.isSaving = false;
          // for (const gallery of this.mainGalleries) {
          //   this.updateHotelPhoto({
          //     id: gallery.id, isMain: gallery.isMain, isHotelPhoto,
          //   });
          // }
        });
      } else {
        this.getHotelRooms().then(async () => {
          room = this.rooms.find(x => x.id === roomId);
          for (const roomx of this.rooms) {
            if (roomGalleries.length > 0) {
              const roomI = roomGalleries.filter(x => x.id === roomx.id);
              if (roomI.length > 0) {
                for (const i of roomI) {
                  const find = roomx.images.find(x => x.url === i.name);
                  if (find) {
                    find.isMain = i.response.data.isMain;
                  }
                }
              }
            }
          }

          this.isSaving = true;
          await updates()
          this.isSaving = false;
          this.sortAll();
        })
      }
    },
    async onUploadError() {
      this.isSaving = false;
    },
    async setPhotoMain(file, galleries, isHotelPhoto = true, roomId) {
      const checked = file.isMain;
      for (const gallery of galleries) {
        if (gallery.isMain) {
          gallery.isMain = false;
          if (gallery.id) {
            await this.updateHotelPhoto({
              id: gallery.id, isMain: gallery.isMain, isHotelPhoto, roomId,
            });
          }
        }
      }
      file.isMain = checked;
      if (file.id) {
        await this.updateHotelPhoto({
          id: file.id,
          isMain: file.isMain,
          isHotelPhoto,
          roomId,
        })
      }
    },
    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.image = file;
    },
    OnPhotoChange(file, fileList, isHotelPhoto = true, room) {
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isLt3M) {
        this.$vs.notify({
          title: "Error",
          text: 'Photo size can not exceed 3MB!',
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          time: 5000,
        });
        fileList.pop();
        return;
      }
      if (isHotelPhoto) {
        this.mainGalleries = fileList;
      } else {
        room.images = fileList;
        console.log(room.images)
      }
    },
    async updateHotelPhoto({ id, roomId, isHotelPhoto, isMain }) {
      try {
        this.isSaving = true;
        const url = isHotelPhoto ? `hotels/${this.hotelId}/images/${id}` :
          `hotels/${this.hotelId}/rooms/${roomId}/images/${id}`
        await axios.put(url, { isMain })
        if (isMain) {
          this.$vs.notify({
            title: "Success",
            text: 'Set main image Success',
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });
        }
        this.sortAll();
      } catch (e) {
        this.$catchErrorResponse(e)
      } finally {
        this.isSaving = false;
      }
    },
    async getMainGalleries() {
      this.$vs.loading({
        type: 'sound'
      })
      try {
        const { data: res } = await axios.get(`hotels/${this.hotelId}/images`)
        this.mainGalleries = res.data.sort((x, y) => y.isMain);
      } catch (e) {
        this.$catchErrorResponse(e)
      } finally {
        this.isLoadedMainGallery = true
        this.$vs.loading.close()
      }
    },

    async getHotelRooms() {
      this.$vs.loading({
        type: 'sound'
      })
      try {
        const { data: res } = await axios.get(`hotels/${this.hotelId}/rooms`);
        this.rooms = res.data.rooms;
        for (const room of res.data.rooms) {
          room.images.sort((x, y) => y.isMain);
        }
      } finally {
        this.isLoadedPropertyGallery = true
        this.$vs.loading.close()
      }
    },

    resetForm() {
      this.url = "https://dummyimage.com/600x400/e3e3e3/0d0d0d";
      this.productPropertyID = null
      this.payload = {
        image: null,
        status: 1,
        isMain: false,
        roomImages: []
      };
    },

    storeData() {
      const formData = new FormData();
      let url = ''

      formData.set("status", this.payload.status);
      if (this.productPropertyID == null) {
        formData.set("isMain", this.payload.isMain == true ? 1 : 0);
      } else {
        formData.set("product_property_id", this.productPropertyID.id);
        formData.set("isMain_property", this.payload.isMain == true ? 1 : 0);
      }

      formData.append(
          "product_properties",
          JSON.stringify(this.payload.product_properties)
        );
      if (this.payload.image !== null) {
        formData.set('image', this.payload.image)
      }

      // check action is store or update
      if (this.galleryID == null) {
        url = `vancynet/brand-gallery`
      } else {
        url = `vancynet/brand-gallery/${this.galleryID}`
        formData.set('_method', 'PUT')
      }

      axios
        .post(url, formData)
        .then(async ({ data: res }) => {
          this.$vs.notify({
            title: "Success",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.popup = false;
          this.galleryID = null;

          this.resetForm();
          await this.getHotelRooms();
          await this.getMainGalleries();
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    confirmDelete(galleryID, productPropertyID) {
      this.galleryID = galleryID;
      this.productPropertyID = productPropertyID

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Confirm Delete",
        text: `You are about to delete this image`,
        accept: this.destroyData,
        acceptText: "Delete"
      });
    },

    destroyData() {
      let url = ''

      if (this.productPropertyID !== null) {
        url = `vancynet/brand-gallery/${this.galleryID}?product_property=${this.productPropertyID}`
      } else {
        url = `vancynet/brand-gallery/${this.galleryID}`
      }
      axios
        .delete(url)
        .then(async ({ data: res }) => {
          this.$vs.notify({
            title: "Success",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.galleryID = null;
          this.productPropertyID = null;

          await this.getHotelRooms();
          await this.getMainGalleries();
        })
        .catch(err => this.$catchErrorResponse(err));
    },
    sortAll() {
      this.mainGalleries = this.mainGalleries.sort((x, y) => y.isMain);
      for (const room of this.rooms) {
        room.images.sort((x, y) => y.isMain);
      }
    },
  },

  mounted() {
    this.url = "https://dummyimage.com/600x400/e3e3e3/0d0d0d";
  },

  async created() {
    await this.getMainGalleries();
    await this.getHotelRooms();
  }
};
</script>

<style scoped lang="scss">
#gallery-list /deep/ {
  .el-upload.el-upload--picture-card {
    width: 300px;
    float: left;
    margin-right: .75rem;
    margin-bottom: 2rem;
    display: block;
  }
  .el-upload-dragger {
    width: 300px;
  }
  .el-icon-upload {
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
    line-height: 3rem;
    position: relative;
    display: block;
  }
  .el-upload__text {
    line-height: 1.5rem;
  }
  .el-upload-list__item-thumbnail {
    object-fit: contain;
    height: 150px;
  }
  .el-upload-list.el-upload-list--picture-card {
    margin-bottom: 1rem;
    height: fit-content;
    display: inline-block;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    overflow: visible;
  }
  .upload-status {
    color: green;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
  }
}

</style>
